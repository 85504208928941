<template>
  <div class="foot p-pl-2 p-mb-3">
      Copyright © 2021 bogorpools.com Corporation, All Right Reserved
  </div>
</template>

<script>
export default {};
</script>

<style>
.foot{
  color:white;
   text-shadow: 1px 1px 5px #333;
}
</style>