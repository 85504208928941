<template>
  <div
    id="app"
    class="p-col-12"
    style="background-repeat: no-repeat; background-size: auto"
  >
    <div class="headerBg p-col-12 p-jc-center p-p-0 p-m-0">
      <client-header />
    </div>
    <div class="p-lg-offset-2 p-lg-8 p-col-12 p-mt-md-3 p-shadow-24 card">
      <router-view />
    </div>
    <client-footer class="p-pl-0 p-mt-3 p-lg-offset-2 p-lg-8 p-col-12" />
  </div>
</template>

<script>
import ClientFooter from "../components/client/ClientFooter.vue";
import ClientHeader from "../components/client/ClientHeader.vue";

export default {
  name: "Home",
  components: {
    ClientHeader,
    ClientFooter,
  },
  data() {
    return {
      activeIndex: 0,
      env: process.env.VUE_APP_BACKEND_API,
      web_data: null,
    };
  },
  methods: {
    async getWebContent() {
      await this.$http.get("/web").then((r) => {
        this.web_data = r.data;
        document.querySelector("body").style.background =
          "url(" +
          this.env +
          "/image/" +
          r.data.web_banner +
          ") no-repeat fixed center 5vh/cover";
        const favicon = document.getElementById("favicon");
        favicon.href = this.env + "/image/" + r.data.web_favicon;
      });
    },
    async getWebTime() {
      await this.$http.get("/time").then((r) => {
        this.$store.commit("setWaktu", r.data);
      });
    },
  },
  mounted() {
    this.getWebTime();
    this.getWebContent();
  },
};
</script>
<style lang="scss">
@import "../client.scss";
</style>
