<template>
  <div class="p-lg-offset-2 p-lg-8 p-col-12 p-grid p-py-0 p-my-0">
    <router-link
      to="/"
      tag="img"
      width="150px"
      height="150px"
      class="p-lg-2 p-col-12 p-as-center"
      style="
        object-fit: contain;
        cursor: pointer;
        -webkit-filter: drop-shadow(1px 1px 7px #000);
        filter: drop-shadow(1px 1px 7px #000);
      "
      :src="require('@/assets/bogorpools.png')"
    />
    <div
      class="p-lg-10 p-pb-0 p-mb-0 p-col-12 p-d-flex p-flex-column p-jc-around"
    >
      <div class="p-d-flex">
        <router-link to="/">Home</router-link>
        <div class="verticalLine p-mx-2" />
        <router-link to="/responsible-gaming">Responsible Gaming</router-link>
        <div class="verticalLine p-mx-2" />
        <router-link to="/about-us">About Us</router-link>
        <div class="verticalLine p-mx-2" />
        <router-link to="/contact-us">Contact Us</router-link>

        <!-- <router-link to="/" class="p-js-end">Contac Us</router-link> -->
      </div>
      <h1 class="p-ml-0 p-pl-0">BOGOR POOLS</h1>
      <div class="p-d-flex p-flex-wrap">
        <router-link to="/">
          <Button label="Home" class="header" />
        </router-link>

        <router-link to="/result/Sunday">
          <Button label="Sunday" class="header" />
        </router-link>

        <router-link to="/result/Monday">
          <Button label="Monday" class="header" />
        </router-link>

        <router-link to="/result/Tuesday">
          <Button label="Tuesday" class="header" />
        </router-link>

        <router-link to="/result/Wednesday">
          <Button label="Wednesday" class="header" />
        </router-link>

        <router-link to="/result/Thursday">
          <Button label="Thursday" class="header" />
        </router-link>

        <router-link to="/result/Friday">
          <Button label="Friday" class="header" />
        </router-link>

        <router-link to="/result/Saturday">
          <Button label="Saturday" class="header" />
        </router-link>

        <router-link to="/live-draw">
          <Button label="Live Draw" class="live" />
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>